@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  /* Remove default focus outline */
  *:focus {
    outline: none;
  }

  /* Add custom focus styles for accessibility */
  *:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: none; /* Subtle indigo shadow */
  }

  /* For touch devices */
  @media (hover: none) {
    *:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="number"]:focus,
  .Input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@media (min-width: 640px) {
  .desktop-no-spinner {
    -moz-appearance: textfield;
  }

  .desktop-no-spinner::-webkit-outer-spin-button,
  .desktop-no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Add these rules to your existing CSS */

/* iPad-specific styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .ipad-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 256px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .ipad-main {
    margin-left: 256px;
    width: calc(100% - 256px);
  }
}

/* Ensure full height on mobile devices */
@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

/* Hide scrollbars on WebKit browsers */
.ipad-sidebar::-webkit-scrollbar,
.ipad-main::-webkit-scrollbar {
  display: none;
}

/* Optional: Hide scrollbars on Firefox */
.ipad-sidebar, .ipad-main {
  scrollbar-width: none;
}
